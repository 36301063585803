import React, { useState } from 'react';
import Layout from '../components/Layout';
import { navigate } from 'gatsby';

const InvitationAddress = (props) => {
  const [submitted, setSubmitted] = useState(false);

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [stateField, setStateField] = useState('');
  const [zip, setZip] = useState('');

  const onSubmit = () => {
    setSubmitted(true);
  };

  const submitButtonDisabled =
    !(name && address && city && stateField && zip) || submitted;

  return (
    <Layout fullMenu location={props.location} title="Invitation Address">
      <article id="main">
        <header>
          <h2>Invitation Address</h2>
          {/* <p>Please enter your address</p> */}
        </header>
        <section className="wrapper style5 rsvp">
          <iframe
            title="hidden_iframe"
            name="hidden_iframe"
            id="hidden_iframe"
            style={{ display: 'none' }}
            onLoad={() => {
              if (submitted) {
                navigate('/thankyou');
              }
            }}
          ></iframe>

          <div className="inner">
            <p>We would be honored by your presence at our wedding.</p>
            <p>Please enter your address so we can send you an invitation!</p>

            <header>
              <p>Saturday, December 18, 2021 | Arlington, Texas</p>
            </header>

            <hr />
            <form
              action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSfINnUznRZ4yAp-GoA4Wttmx75f0uSvvDtULAMfeEh1WThCqw/formResponse"
              onSubmit={onSubmit}
              method="post"
              target="hidden_iframe"
            >
              <div className="row gtr-uniform form-row">
                <div className="col-12 col-12-xsmall">
                  <label className="label" htmlFor="name">
                    Name / Tên
                  </label>
                  <input
                    type="text"
                    name="entry.843302724"
                    id="name"
                    defaultValue=""
                    placeholder="Name"
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>

              <div className="row gtr-uniform form-row">
                <div className="col-6 col-12-xsmall">
                  <label className="label" htmlFor="name">
                    Street Address / Địa Chỉ
                  </label>
                  <input
                    type="text"
                    name="entry.1782999863"
                    id="name"
                    defaultValue=""
                    placeholder="Address"
                    required
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div className="col-6 col-12-xsmall">
                  <label className="label" htmlFor="name">
                    City / Thành Phố
                  </label>
                  <input
                    type="text"
                    name="entry.654112764"
                    id="name"
                    defaultValue=""
                    placeholder="City"
                    required
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
              </div>

              <div className="row gtr-uniform form-row">
                <div className="col-6 col-12-xsmall">
                  <label className="label" htmlFor="name">
                    State / Tiểu Bang
                  </label>
                  <input
                    type="text"
                    name="entry.2041479700"
                    id="name"
                    defaultValue=""
                    placeholder="State"
                    required
                    onChange={(e) => setStateField(e.target.value)}
                  />
                </div>
                <div className="col-6 col-12-xsmall">
                  <label className="label" htmlFor="name">
                    Zip Code / Bưu Mã
                  </label>
                  <input
                    type="text"
                    name="entry.985555017"
                    id="name"
                    defaultValue=""
                    placeholder="Zipcode"
                    required
                    onChange={(e) => setZip(e.target.value)}
                  />
                </div>
              </div>

              <div className="row gtr-uniform form-row">
                <div className="col-12">
                  <ul className="actions">
                    <li>
                      <input
                        type="submit"
                        value="Send"
                        className={`primary ${
                          submitButtonDisabled ? 'disabled' : ''
                        }`}
                      />
                    </li>
                    {/* <li>
                      <input type="reset" value="Reset" />
                    </li> */}
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default InvitationAddress;
